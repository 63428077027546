import { Dialog } from "@material-ui/core";
import {
  Box,
  Button,
  DatePicker,
  Field,
  Form,
  Grid,
  Notification,
  TextField,
  Typography,
} from "components";
import { format } from "date-fns";
import { compose, withAuthorize, withFormik, withHooks } from "enhancers";
import newWithTranslation from "enhancers/withTranslation";
import { gql } from "utils/helper";
import { PAYMENT_CYCLE_TABLE_API } from "./PaymentCycleTable";

const CreatePaymentCycleModal = ({ t, isOpen, handleClickCancel }: any) => (
  <Dialog open={isOpen}>
    <Form>
      <Box width={600}>
        <Box display="flex" justifyContent="space-between" mb={6}>
          <Typography variant="h3">{t(".createPaymentCycle")}</Typography>
        </Box>
        <Grid container spacing={6} mb={3}>
          <Grid item xs={12}>
            <Field
              fast
              component={TextField}
              name="name"
              type="text"
              label={t(".name")}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fast
              component={DatePicker}
              name="paymentDate"
              label={t(".paymentDate")}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Box
          style={{ display: "flex", gap: "16px", justifyContent: "flex-end" }}
        >
          <Button variant="outlined" onClick={handleClickCancel}>
            {t(".cancelButtonLabel")}
          </Button>
          <Button type="submit">{t(".okButtonLabel")}</Button>
        </Box>
      </Box>
    </Form>
  </Dialog>
);
const API = {
  CREATE_PAYMENT_CYCLE: gql`
    mutation CREATE_PAYMENT_CYCLE($name: String, $paymentDate: String) {
      createPaymentCycle(input: { name: $name, paymentDate: $paymentDate }) {
        id
      }
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withFormik({
    mapPropsToValues: () => ({
      name: "",
      paymentDate: undefined,
    }),
  }),
  newWithTranslation({
    prefix: "pages.main.paymentCycles.CreatePaymentCycleModal",
  }),
  withHooks((props: any, hooks: any) => {
    const { isOpen, onClose, t, resetForm } = props;

    const { useCallback, useHandleSubmit, useMutation } = hooks;

    const [createPaymentCycle] = useMutation(API.CREATE_PAYMENT_CYCLE, {
      refetchQueries: [{ query: PAYMENT_CYCLE_TABLE_API.PAYMENT_CYCLE_LIST }],
      awaitRefetchQueries: true,
    });

    useHandleSubmit(async (values: any) => {
      try {
        await createPaymentCycle({
          variables: {
            name: values.name,
            paymentDate: format(new Date(values.paymentDate), "yyyy-MM-dd"),
          },
        });
        resetForm();
        onClose();
        Notification.notify("ทำรายการสำเร็จ");
      } catch (error) {
        console.log(error);
      }
    });

    const handleClickCancel = useCallback(() => {
      onClose();
      resetForm();
    }, [onClose, resetForm]);

    return { t, isOpen, handleClickCancel };
  })
);

export default enhancer(CreatePaymentCycleModal);
