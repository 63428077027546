import React from "react";
import MuiTextField from "@material-ui/core/TextField";
import { Autocomplete } from "formik-material-ui-lab";
import { compose, defaultProps, withHooks } from "enhancers";
import { find, map, get, isNil } from "lodash";
import ListboxComponent from "./ListboxComponent";
import Hidden from "components/common/Hidden";
import Fuse from "fuse.js";

import InputAdornment from "@material-ui/core/InputAdornment";

import {
  borders,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
} from "@material-ui/system";
import styled from "styled-components/macro";
import T from "../T";
import { replaceNameSpace } from "utils/helper";
import { makeStyles } from "@material-ui/core";

const makeBoxProps = (Component) =>
  styled(Component)(
    borders,
    display,
    flexbox,
    palette,
    positions,
    shadows,
    sizing,
    spacing,
    typography
  );

const TextField = makeBoxProps(MuiTextField);

const useStyles = makeStyles({
  listbox: {
    "& ul": {
      padding: 0, // ปรับระยะขอบด้านใน
      width: "150% !important",
    },
    "& li": {
      width: "150% !important",
    },
  },
});

export const enhancer = compose(
  defaultProps({
    options: [],
    forceFix: true,
  }),
  withHooks((props, hooks) => {
    const {
      label,
      options,
      fuse,
      transformDisplay,
      requiredLabel,
      ...restProps
    } = props;

    const { useMemo, useCallback } = hooks;

    const customOptions = useMemo(() => {
      return map(options, "value");
    }, [options]);

    const freeSolo = props.freeSolo;
    const customGetOptionLabel = useCallback(
      (value) => {
        const item = find(options, { value });
        const label = item?.label || "";

        if (!label && freeSolo) {
          return value;
        } else {
          return label;
        }
      },
      [options, freeSolo]
    );

    const FormHelperTextProps = useMemo(
      () => ({
        component: (props) => (
          <T className={props.className}>
            {replaceNameSpace("error", props.children)}
          </T>
        ),
      }),
      []
    );

    const touched = get(props.form.touched, props.field.name);
    const error = get(props.form.errors, props.field.name);
    const value = props.field.value;
    const required = props.required;
    const helperText = props.helperText;
    const placeholder = props.placeholder;
    const icon = find(options, { value })?.icon;
    const renderInput = useCallback(
      (params) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={(touched && error) || helperText}
          placeholder={placeholder}
          label={label}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
            required: requiredLabel,
          }}
          value={value}
          required={required}
          InputProps={{
            ...params.InputProps,
            required: false,
            startAdornment: (
              <>
                <Hidden when={!icon}>
                  <InputAdornment position="start">{icon}</InputAdornment>
                </Hidden>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          FormHelperTextProps={FormHelperTextProps}
        />
      ),
      [
        label,
        touched,
        error,
        value,
        icon,
        required,
        helperText,
        placeholder,
        requiredLabel,
        FormHelperTextProps,
      ]
    );

    const filterOptions = useCallback(
      (options, { inputValue }) => {
        if (inputValue || options.length > 200) {
          options = new Fuse(props.options, {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: ["value"],
            id: "label",
          }).search(inputValue ?? "", { limit: 5 });
          options = map(options, "item.value");
        }
        return options;
      },
      [props.options]
    );

    const autoSelect =
      props.freeSolo && isNil(props.autoSelect) ? true : props.autoSelect;

    const classes = useStyles();

    return {
      key: props.forceFix ? value : undefined,
      ...restProps,
      options: [...customOptions],
      getOptionLabel: customGetOptionLabel,
      renderInput,
      ListboxComponent,
      filterOptions: fuse ? filterOptions : undefined,
      blurOnSelect: true,
      autoSelect,
      classes: { listbox: classes.listbox },
    };
  })
);

export default enhancer(Autocomplete);
