import { Box, Button, FilterTableLayout, Form } from "components";

import { compose, withFormik, withHooks, withTranslation } from "enhancers";
import { filter, isEmpty, isNil, keys } from "lodash";
import { filterEmptyValues, Yup } from "utils/helper";

const FilterTableWithQueryParams = ({
  t,
  children,
  filterCount,
  handleClearValues,
}: any) => (
  <FilterTableLayout filterCount={filterCount}>
    <Form>
      {children}
      <Box display="flex" flexDirection="row-reverse" mt={4}>
        <Button width={74} type="submit">
          {t(".confirm")}
        </Button>
        <Button
          width={74}
          mr={6}
          onClick={handleClearValues}
          variant="outlined"
        >
          {t(".reset")}
        </Button>
      </Box>
    </Form>
  </FilterTableLayout>
);

const enhancer = compose(
  withFormik({
    mapPropsToValues: (props: any) => props.initialValues,
    validationSchemaProps: (props: any) => {
      return props.validationSchema || Yup.object();
    },
  }),
  withTranslation({
    prefix: "components.advance.FilterTableWithQueryParams",
  }),
  withHooks((props: any, hooks: any) => {
    const { children, path, resetForm, setValues } = props;

    const {
      useMemo,
      useCallback,
      useHandleSubmit,
      useUrlParam,
      useParams,
      useEffect,
    } = hooks;

    const queryParams = useUrlParam();
    const { id } = useParams();

    useHandleSubmit((values: any) => {
      if (id) path(id, filterEmptyValues(values)).push();
      else path(filterEmptyValues(values)).push();
    });

    const handleClearValues = useCallback(() => {
      resetForm();
      if (id) path(id, {}).push();
      else path({}).push();
    }, [id, path, resetForm]);

    const filterCount = useMemo(() => {
      const count = filter(keys(queryParams), (key) => {
        const value = queryParams[key];
        return !isNil(value) && value !== "";
      }).length;
      return count;
    }, [queryParams]);

    useEffect(() => {
      if (!isEmpty(queryParams)) {
        setValues(queryParams);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { children, handleClearValues, filterCount };
  })
);

export default enhancer(FilterTableWithQueryParams);
