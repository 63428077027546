import { pick, mapValues } from "lodash";
import {
  borders,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
} from "@material-ui/system";
import styled from "styled-components/macro";
import * as MuiCore from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as FormikMuiCore from "formik-material-ui";
import * as FormikMuiPicker from "formik-material-ui-pickers";

import CustomButton from "./Button";
import CustomLink from "./MuiLink";
import CustomCheckbox from "./Checkbox";
import CustomErrorMessage from "./ErrorMessage";
import CustomSelect from "./Select";
import CustomTypography from "./Typography";
import CustomRadioGroup from "./RadioGroup";
import CustomTextField from "./TextField";
import CustomSwitchInput from "./SwitchInput";
import CustomDatePicker from "./DatePicker";
import { Stepper as CustomStepper } from "./Stepper";
import { MaterialIcon as CustomMaterialIcon } from "./MaterialIcon";

const makeBoxProps = (Component: any) =>
  styled(Component)(
    borders,
    display,
    flexbox,
    palette,
    positions,
    shadows,
    sizing,
    spacing,
    typography
  );

CustomTextField.Email = makeBoxProps(CustomTextField.Email);
CustomTextField.PhoneNumber = makeBoxProps(CustomTextField.PhoneNumber);
CustomTextField.TaxId = makeBoxProps(CustomTextField.TaxId);

const CustomMuiTextField = makeBoxProps(MuiCore.TextField);

export const Email = CustomTextField.Email;
export const PhoneNumber = CustomTextField.PhoneNumber;
export const TaxId = CustomTextField.TaxId;

const MuiComponents = mapValues(
  {
    ...pick(MuiCore, [
      "Avatar",
      "Paper",
      "Menu",
      "Breadcrumbs",
      "Divider",
      "IconButton",
      "Radio",
      "FormControlLabel",
      "Grid",
      "Chip",
    ]),
    ...pick(MuiLab, ["Alert"]),
    ...pick(FormikMuiCore, []),
    Button: CustomButton,
    Link: CustomLink,
    Checkbox: CustomCheckbox,
    ErrorMessage: CustomErrorMessage,
    Select: CustomSelect,
    // Typography: CustomTypography,
    RadioGroup: CustomRadioGroup,
    TextField: CustomTextField,
    SwitchInput: CustomSwitchInput,
    MuiTextField: CustomMuiTextField,
    DatePicker: CustomDatePicker,
    Stepper: CustomStepper,
  },
  makeBoxProps
);

export const {
  Avatar,
  Paper,
  TextField,
  // Typography,
  Alert,
  Button,
  Link,
  Checkbox,
  Menu,
  Breadcrumbs,
  Divider,
  ErrorMessage,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  Grid,
  Chip,
  SwitchInput,
  MuiTextField,
  Stepper,
} = MuiComponents;

export const KeyboardDatePicker = FormikMuiPicker.KeyboardDatePicker;
export const Typography = CustomTypography;
export const MaterialIcon = CustomMaterialIcon;
export const Box = MuiCore.Box;
export const Tabs = MuiCore.Tabs;
export const Tab = MuiCore.Tab;
export const Tooltip = MuiCore.Tooltip;
export const Accordion = MuiCore.Accordion;
export const AccordionSummary = MuiCore.AccordionSummary;
export const AccordionDetails = MuiCore.AccordionDetails;

export const ClickAwayListener = MuiCore.ClickAwayListener;

export { CKEditor } from "./CKEditor";
export { DataGrid, GridToolbar } from "@material-ui/data-grid";
export { default as DeleteIcon } from "@material-ui/icons/Delete";

export { Helmet } from "react-helmet-async";
export { AnimatedSwitch } from "react-router-transition";

export { default as Hidden } from "./Hidden";
export { default as BrowseButton } from "./BrowseButton";

export { default as Form } from "./Form";
export { default as Field } from "./Field";
export { default as FieldArray } from "./FieldArray";

export { default as BrowserRouter } from "./BrowserRouter";
export { default as Redirect } from "./Redirect";
export { default as Route } from "./Route";
export { default as Switch } from "./Switch";

export { default as Modal } from "./Modal";
export { default as Notification } from "./Notification";
export { default as ExcelGenerator } from "./ExcelGenerator";
export { default as ImageLightbox } from "./ImageLightbox";

export { default as Icon } from "./Icon";
export { default as PopMenu } from "./PopMenu";
export { default as UploadAvatar } from "./UploadAvatar";

export { default as Table } from "./Table";
export { default as Editor } from "./Editor";

export { default as DatePicker } from "./DatePicker";
export { default as ProgressBar } from "./ProgressBar";

export { default as JsonEditor } from "./JsonEditor";
export { default as Cron } from "./cron/index";

export { default as T } from "./T";
