import {
  Box,
  DatePicker,
  Field,
  FilterTableWithQueryParams,
  Grid,
  Select,
  TextField,
} from "components";
import { EnumPaymentCycleStatus } from "constants/enums/payment_cycle_status";

import { compose, withHooks, withTranslation } from "enhancers";
import paths from "routes/paths";
import { createYupDateObject } from "./detail/PaymentCycleDetailFilters";
import { Yup } from "utils/helper";

interface IPaymentCycleListFilterInput {
  name?: string;
  status?: EnumPaymentCycleStatus;
  startPaymentDate?: string;
  endPaymentDate?: string;
}

export const paymentCycleListFilterInitialValues: IPaymentCycleListFilterInput = {
  name: "",
  status: undefined,
  startPaymentDate: undefined,
  endPaymentDate: undefined,
};

const validationSchema = Yup.object().shape({
  startPaymentDate: createYupDateObject(
    "startPaymentDate",
    "endPaymentDate",
    "LTE"
  ),
  endPaymentDate: createYupDateObject(
    "endPaymentDate",
    "startPaymentDate",
    "GTE"
  ),
});

const PaymentCycleFilters = ({ t }: any) => (
  <FilterTableWithQueryParams
    initialValues={paymentCycleListFilterInitialValues}
    path={paths.paymentCyclePath}
    validationSchema={validationSchema}
  >
    <Box display="flex" flexDirection="column">
      <Grid container spacing={6} mt={6}>
        <Grid item xs={12}>
          <Field
            fast
            component={TextField}
            name="name"
            type="text"
            label={t(".name")}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            fast
            component={Select}
            name="status"
            label={t(".status")}
            options={Object.values(EnumPaymentCycleStatus).map((value) => ({
              label: t(`.statusOptions.${value}`),
              value: value,
            }))}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            fast
            component={DatePicker}
            name="startPaymentDate"
            label={t(".startPaymentDate")}
            fullWidth
            clearable
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            fast
            component={DatePicker}
            name="endPaymentDate"
            label={t(".endPaymentDate")}
            fullWidth
            clearable
          />
        </Grid>
      </Grid>
    </Box>
  </FilterTableWithQueryParams>
);

const enhancer = compose(
  withTranslation({
    prefix: "pages.main.paymentCycles.PaymentCycleFilters",
  }),
  withHooks((props: any, hooks: any) => {
    const { t } = props;

    return { t };
  })
);

export default enhancer(PaymentCycleFilters);
