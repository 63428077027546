import { GridColDef } from "@material-ui/data-grid";
import { ReactComponent as RedTrashIcon } from "assets/icon/red_trash.svg";
import { ReactComponent as ViewIcon } from "assets/icon/view-icon.svg";
import {
  Box,
  Button,
  IconButton,
  MaterialIcon,
  Modal,
  Table,
  Tooltip,
  Typography,
} from "components";
import { EnumPaymentCycleStatus } from "constants/enums/payment_cycle_status";
import { PERMISSIONS } from "constants/enums/permissions";
import { compose, withAuthorize, withHooks, withTranslation } from "enhancers";
import { gql, useLazyQuery, useQuery } from "hooks";
import { isEmpty } from "lodash";
import paths from "routes/paths";
import { AppColor } from "theme/app-color";
import { formatDateWithBuddhistYear } from "utils/helper";
import { paymentCycleListFilterInitialValues } from "./PaymentCycleFilters";
import { PAYMENT_CYCLE_DETAIL_API } from "./detail";
const changeTo = {
  [EnumPaymentCycleStatus.IN_PROGRESS]: EnumPaymentCycleStatus.CLOSED,
  [EnumPaymentCycleStatus.CLOSED]: EnumPaymentCycleStatus.IN_PROGRESS,
};
export const waiting_requests_id = "waiting-requests";
const PaymentCycleTableComponent = (props: any) => (
  <Table
    columns={props.columns}
    rows={props.tableData}
    loading={props.loading}
    density="compact"
    autoHeight
    disableSelectionOnClick
  />
);

export const PAYMENT_CYCLE_TABLE_API = {
  PAYMENT_CYCLE_LIST: gql`
    query PAYMENT_CYCLE_LIST(
      $name: String
      $status: String
      $startPaymentDate: String
      $endPaymentDate: String
    ) {
      paymentCycleList(
        input: {
          name: $name
          status: $status
          startPaymentDate: $startPaymentDate
          endPaymentDate: $endPaymentDate
        }
      ) {
        id
        name
        status
        paymentDate
        requestTotalPaymentAmount
        requestAmount
      }
    }
  `,
  DELETE_PAYMENT_CYCLE: gql`
    mutation DELETE_PAYMENT_CYCLE($id: String!) {
      deletePaymentCycle(id: $id)
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withTranslation({
    prefix: "pages.main.paymentCycles.PaymentCycleTable",
  }),
  withHooks((props: any, hooks: any) => {
    const { t, hasPermission } = props;
    const { useMemo, useCallback, useEffect, useUrlParam, useMutation } = hooks;
    const queryParams = useUrlParam();

    const { data: waitingRequestDetail } = useQuery(
      PAYMENT_CYCLE_DETAIL_API.GET_PAYMENT_CYCLE_DETAIL
    );
    const [fetchList, { data, loading, error }] = useLazyQuery(
      PAYMENT_CYCLE_TABLE_API.PAYMENT_CYCLE_LIST,
      {
        fetchPolicy: "network-only",
      }
    );
    const [deletePaymentCycle] = useMutation(
      PAYMENT_CYCLE_TABLE_API.DELETE_PAYMENT_CYCLE,
      {
        refetchQueries: [{ query: PAYMENT_CYCLE_TABLE_API.PAYMENT_CYCLE_LIST }],
        awaitRefetchQueries: true,
      }
    );

    const handleConfirmDeletePaymentCycle = useCallback(
      async (id: string) => {
        try {
          await deletePaymentCycle({ variables: { id } });
        } catch (error) {
          console.log(error);
        }
      },
      [deletePaymentCycle]
    );

    const handleClickDeleteRow = useCallback(
      async ({ row }: any) => {
        console.log(row);
        Modal.open({
          title: t(".confirmDeleteModal.removePaymentCycle"),
          children: (
            <Box display="flex" flexDirection="column">
              <div style={{ marginBottom: "16px" }}>
                <Typography variant="Body/16">
                  {t(".confirmDeleteModal.info1")}
                </Typography>
                <Typography
                  variant="Subtitle/16"
                  color={AppColor["Primary/Primary"]}
                >
                  {`“${row.name}”`}
                </Typography>
                <Typography variant="Body/16">
                  {t(".confirmDeleteModal.info2")}
                </Typography>
              </div>
              <Typography variant="Helper/12" color={AppColor["Other/Warning"]}>
                {t(".confirmDeleteModal.warningInfo")}
              </Typography>
            </Box>
          ),
          cancelButtonLabel: t(".confirmDeleteModal.cancelDelete"),
          okButtonLabel: t(".confirmDeleteModal.confirmDelete"),
          okButtonVariant: "contained",
          okButtonColor: AppColor["SecondaryText/Danger"],
          cancelButtonVariant: "outlined",
          onOk: async ({ close }: any) => {
            close();
            handleConfirmDeletePaymentCycle(row.id);
          },
        });
      },
      [handleConfirmDeletePaymentCycle, t]
    );

    const handleClickToSeeDetail = useCallback((row: any) => {
      paths.paymentCycleDetailPath(row.id).push();
    }, []);

    const [updateStatus] = useMutation(
      PAYMENT_CYCLE_DETAIL_API.UPDATE_PAYMENT_CYCLE_DETAIL,
      {
        refetchQueries: [
          {
            query: PAYMENT_CYCLE_TABLE_API.PAYMENT_CYCLE_LIST,
          },
        ],
        awaitRefetchQueries: true,
      }
    );

    const confirmToChangeStatus = useCallback(
      async (id: string, status: EnumPaymentCycleStatus) => {
        await updateStatus({
          variables: { id, status: changeTo[status] },
        });
      },
      [updateStatus]
    );
    const handleClickToChangeStatus = useCallback(
      async (row: any) => {
        const changeStatusTo = {
          [EnumPaymentCycleStatus.CLOSED]: {
            title: t(".changeStatusToCloseModal.title"),
            children: (
              <Box display="flex" flexDirection="column">
                <div style={{ marginBottom: "16px" }}>
                  <Typography variant="Body/16">
                    {t(".changeStatusToCloseModal.info1")}
                  </Typography>
                  &nbsp;
                  <Typography
                    variant="Subtitle/16"
                    color={AppColor["Primary/Primary"]}
                  >
                    {`“${row.name}”`}
                  </Typography>
                  &nbsp;
                  <Typography variant="Body/16">
                    {t(".changeStatusToCloseModal.info2")}
                  </Typography>
                  &nbsp;
                  <Typography
                    variant="Subtitle/16"
                    color={AppColor["Primary/Primary"]}
                  >
                    {`“${t(
                      `client.enum.paymentCycleStatus.${EnumPaymentCycleStatus.CLOSED}`
                    )}”`}
                  </Typography>
                  &nbsp;
                  <Typography variant="Body/16">
                    {t(".changeStatusToCloseModal.info3")}
                  </Typography>
                </div>
                <Typography variant="Helper/12">
                  {t(".changeStatusToCloseModal.subInfo")}
                </Typography>
              </Box>
            ),
            okButtonLabel: t(`.changeStatusToCloseModal.confirm`),
            okButtonVariant: "contained",
            okButtonColor: AppColor["SecondaryText/Danger"],
          },
          [EnumPaymentCycleStatus.IN_PROGRESS]: {
            title: t(".changeStatusToInProgressModal.title"),
            children: (
              <div>
                <Typography variant="Body/16">
                  {t(".changeStatusToInProgressModal.info1")}
                </Typography>
                &nbsp;
                <Typography
                  variant="Subtitle/16"
                  color={AppColor["Primary/Primary"]}
                >
                  {`“${row.name}”`}
                </Typography>
                &nbsp;
                <Typography variant="Body/16">
                  {t(".changeStatusToInProgressModal.info2")}
                </Typography>
                &nbsp;
                <Typography
                  variant="Subtitle/16"
                  color={AppColor["Primary/Primary"]}
                >
                  {`“${t(
                    `client.enum.paymentCycleStatus.${EnumPaymentCycleStatus.IN_PROGRESS}`
                  )}”`}
                </Typography>
                &nbsp;
                <Typography variant="Body/16">
                  {t(".changeStatusToInProgressModal.info3")}
                </Typography>
              </div>
            ),
            okButtonLabel: t(`.changeStatusToInProgressModal.confirm`),
            okButtonVariant: "contained",
            okButtonColor: AppColor["Primary/Primary"],
          },
        };
        Modal.open({
          ...changeStatusTo[changeTo[row.status as EnumPaymentCycleStatus]],
          cancelButtonLabel: t(".changeStatusToCloseModal.cancel"),
          cancelButtonVariant: "outlined",
          onOk: async ({ close }: any) => {
            close();
            await confirmToChangeStatus(row.id, row.status);
          },
        });
      },
      [confirmToChangeStatus, t]
    );

    const tableData = useMemo(() => {
      if (loading || error || !data) {
        return [];
      }

      const waitingDetail = waitingRequestDetail?.paymentCycleDetail;
      const waitingItems = isEmpty(queryParams)
        ? [
            {
              id: waiting_requests_id,
              name: "รอจัดการ",
              requestAmount: waitingDetail?.requestAmount || 0,
              requestTotalPaymentAmount:
                waitingDetail?.requestTotalPaymentAmount || 0,
              paymentDate: undefined,
              status: undefined,
            },
          ]
        : [];
      return [...waitingItems, ...data?.paymentCycleList].map((cycle: any) => {
        let actions = [
          {
            Icon: ViewIcon,
            onClick: handleClickToSeeDetail,
          },
        ];

        if (
          hasPermission([PERMISSIONS.PAYMENT_CYCLE_EDIT]) &&
          cycle.status &&
          cycle.status === EnumPaymentCycleStatus.IN_PROGRESS &&
          cycle.requestAmount === 0
        ) {
          actions.push({ Icon: RedTrashIcon, onClick: handleClickDeleteRow });
        }

        return {
          ...cycle,
          actions,
        };
      });
    }, [
      loading,
      error,
      data,
      waitingRequestDetail?.paymentCycleDetail,
      queryParams,
      handleClickToSeeDetail,
      hasPermission,
      handleClickDeleteRow,
    ]);

    const columns = useMemo(
      (): GridColDef[] => [
        {
          width: 400,
          field: "name",
          headerName: t(".columns.name"),
          disableColumnMenu: true,
          sortable: false,
        },
        {
          width: 130,
          field: "requestAmount",
          headerName: t(".columns.requestAmount"),
          align: "right",
          headerAlign: "right",
          disableColumnMenu: true,
          type: "currency",
          sortable: false,
        },
        {
          width: 160,
          field: "requestTotalPaymentAmount",
          align: "right",
          headerAlign: "right",
          disableColumnMenu: true,
          type: "currency",
          sortable: false,
          renderHeader: (params) => {
            return (
              <Box display="flex" alignItems="center">
                <Typography variant="Header/16">
                  {t(".columns.requestTotalPaymentAmount")}
                </Typography>
                &nbsp;
                <Tooltip
                  title="รวมจากคำร้องที่ HR อนุมัติแล้วเท่านั้น"
                  aria-label="help"
                  placement="top"
                  arrow
                >
                  <IconButton style={{ padding: 0 }}>
                    <MaterialIcon
                      width="16px"
                      height="16px"
                      name="HelpOutline"
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
        },
        {
          width: 120,
          field: "paymentDate",
          headerName: t(".columns.paymentDate"),
          align: "center",
          headerAlign: "center",
          sortable: false,
          renderCell: (params) => {
            if (params.row.paymentDate)
              return formatDateWithBuddhistYear(
                new Date(params.row.paymentDate),
                "dd/MM/yyyy"
              );
          },
          disableColumnMenu: true,
        },
        {
          width: 120,
          field: "status",
          headerName: t(".columns.status"),
          align: "left",
          headerAlign: "left",
          sortable: false,
          renderCell: (params) => {
            const statusDisplay = {
              [EnumPaymentCycleStatus.IN_PROGRESS]: t(
                `client.enum.paymentCycleStatus.${EnumPaymentCycleStatus.IN_PROGRESS}`
              ),
              [EnumPaymentCycleStatus.CLOSED]: t(
                `client.enum.paymentCycleStatus.${EnumPaymentCycleStatus.CLOSED}`
              ),
            };
            const Display = (props: any) => (
              <Typography {...props}>
                {statusDisplay[params.row.status as keyof typeof statusDisplay]}
              </Typography>
            );
            if (!hasPermission([PERMISSIONS.PAYMENT_CYCLE_EDIT]))
              return <Display />;
            return (
              <Button
                onClick={() => {
                  handleClickToChangeStatus(params.row);
                }}
                variant="text"
              >
                <Display
                  color={AppColor["Other/Info"]}
                  style={{ textDecoration: "underline" }}
                />
              </Button>
            );
          },
          disableColumnMenu: true,
        },
        {
          width: 100,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ],
      [handleClickToChangeStatus, hasPermission, t]
    );

    useEffect(() => {
      const params = isEmpty(queryParams) ? undefined : queryParams;
      const allowedKeys = Object.keys(paymentCycleListFilterInitialValues);
      const hasInvalidKey =
        params && Object.keys(params).some((key) => !allowedKeys.includes(key));
      if (hasInvalidKey) paths.paymentCyclePath().push();
      else {
        fetchList({ variables: params });
      }
    }, [queryParams, fetchList]);

    return { t, columns, tableData, loading, handleClickToSeeDetail };
  })
);

export default enhancer(PaymentCycleTableComponent);
