import qs from "qs";
import { last } from "lodash";
import { history } from "components/common/BrowserRouter";

// prettier-ignore
export class Path extends String {
  newTab = () => {
    window.open(this.toString(), "__blank");
  };

  push = (extendedPath: String = "") => {
    if (history) {
      history.push(`${this.toString()}${extendedPath ?? ""}`);
    }
  };

  reload = () => {
    window.location.href = this.toString();
  };

  makePath = (path: Function): Function => {
    return (...args: any[]) => {
      let pathMethod = typeof path === "string" ? () => path : path;
      let absolutePath = null;

      if (last(args) instanceof Object) {
        const params = args.slice(0, args.length - 1);

        const query = last(args);
        const queryString = qs.stringify(query);

        absolutePath = `${this}/${pathMethod(...params)}?${queryString}`;
      } else {
        absolutePath = `${this}/${pathMethod(...args)}`;
      }

      return new Path(absolutePath.replace(/\/+/, "/"));
    };
  };

  page404Path = this.makePath(() => `404`);
  page500Path = this.makePath(() => `500`);

  signInPath = this.makePath(() => `auth/sign-in`);
  signUpPath = this.makePath(() => `auth/sign-up`);
  setupPasswordPath = this.makePath(() => `auth/setup-password`);
  resetPasswordPath = this.makePath(() => `auth/reset-password`);

  adminsPath = this.makePath(() => `admins`);
  adminNewPath = this.makePath(() => `admins/new`);
  adminEditPath = this.makePath((id: any) => `admins/${id}/edit`);

  rolesPath = this.makePath(() => `roles`);
  roleNewPath = this.makePath(() => `roles/new`);
  roleEditPath = this.makePath((id: any) => `roles/${id}/edit`);

  reportPath = this.makePath(() => `report`);

  settingsPath = this.makePath(() => `settings`);

  employeePath = this.makePath(() => `employee`);
  employeeEditPath = this.makePath((id: any) => `employee/${id}`);

  budgetPath = this.makePath(() => `budget`);
  budgetEditPath = this.makePath((id: any) => `budget/edit/${id}`);

  requestPath = this.makePath(() => `request`);
  requestDetailPath = this.makePath((id: any) => `request/detail/${id}`);
  fileBrowserPath = this.makePath(() => `file-browser`);


  paymentCyclePath = this.makePath(() => `payment-cycles`);
  paymentCycleDetailPath = this.makePath((id: any) => `payment-cycles/${id}`);

  homePath = this.adminsPath;
}

export default new Path();
