import { ENUM_CLAIM_REQUEST_DETAIL_STATUS } from "constants/enums/claim_request_detail_status.enum";

export const ClaimRequestMappingStatus = {
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.APPROVED]: "อนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING]: "รออนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_0]: "รอหัวหน้าแผนกอนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_1]: "รอฝ่ายบุคคลอนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_2]: "รอผู้จัดการกลุ่มฯ อนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_3]: "รอผู้อำนวยการฝ่ายฯ อนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_4]: "รอผู้ช่วย ปจบ. อนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_5]: "รอรอง ปจบ. อนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_6]: "รอปจบ. อนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_EMPLOYEE_UPDATE]: "รอพนักงานแก้ไข",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_HR_UPDATE]: "รอ HR แก้ไข",
};
