const en = {
  translation: {
    client: {
      enum: {
        paymentCycleStatus: {
          IN_PROGRESS: "in progress",
          CLOSED: "closed",
        },
      },
      enhancers: {
        withPreventLeaveDirtyForm: {
          cancelEdit: "Cancel Edit",
          cancelConfirm:
            "Current data will not be saved. and cannot be restored",
          close: "Close Window",
          canceledEdit: "Canceled Edit",
        },
      },
      api: {
        modal401Title: "Login expired",
        modal401Children: "Login expired Please log in again.",
        modal401OkButtonLabel: "Login",
        modal403Title: "Error",
        modal403Children:
          "Your user role doesn't have access to this section. Please log in again.",
        modal403OkButtonLabel: "Login",
        modal500Title: "Error",
        modal500OkButtonLabel: "Ok",
      },
      pages: {
        main: {
          admins: {
            index: {
              title: "Admin",
              home: "Home",
              addButton: "Add admin",
              admins: "Admin",
              deleteButton: "Delete admin",
              removeTitle: "ลบข้อมูลผู้ดูแลระบบ",
              cancel: "ปิดหน้าต่างนี้",
              removeAdmin: "ลบข้อมูลทั้งหมด",
              remove: "ลบข้อมูลสำเร็จ",
              titleRemove: "ข้อมูลของผู้ดูแลระบบ",
              removeText:
                "รวมทั้งรายงานที่สร้างไว้ส่วนตัวจะถูกลบและไม่สามารถกู้คืนได้",
              columns: {
                fullName: "Full name",
                email: "Email",
                status: "Status",
                code: "Code",
                lastActiveAt: "Last active at",
                createdAt: "Created at",
                updatedAt: "Updated at",
                role: "Role",
                values: {
                  availableStatus: {
                    active: "active",
                    inactive: "inactive",
                  },
                },
              },
            },
            new: {
              home: "home",
              rootPath: "Admin",
              title: "Add admin",
              header: "Input admin email",
              link: "URL link would be sent to this email",
              send: "Send",
              cancel: "Cancel",
              email: "Email",
              role: "Role",
              notificationSuccess: "successful transaction",
            },
            edit: {
              title: "General Data",
              home: "Home",
              status: "Status",
              rootPath: "Admin",
              cancel: "Cancel",
              save: "Save",
              cantEditYourself:
                "Editing status and roles cannot be done by yourself, only an Admin who has permission to edit permission of Admin can do so, such as Super Admin.",
              firstName: "First Name",
              lastName: "Last Name",
              role: "Role",
              email: "Email",
              available: "Available",
            },
          },
          authentication: {
            LoginPage: {
              or: "or",
              title: "eBenefits",
              title1: "Fill email and receive OTP for sign-in",
              title2: "Fill email and receive OTP for sign-up",
              title3: "Email and password to log in",
              emailField: "Email",
              passwordField: "Password",
              getOtp: "Request OTP",
              invalidEmail: "Email is incorrect",
              signInWithAzureAd: "Sign in with Azure AD",
              logoutAzureAd: "Sign out Azure AD",
              ResetPassword: "Forgot your password",
              login: "Login",
            },
            VerifyOtp: {
              title: "Fill OTP for sign-in",
              otpInfo:
                "Fill OTP from {{currentEmail}} (OTP expire in {{otpExpireInMin}} minutes)",
              otp: "OTP",
              refCode: "ref:{{refCode}}",
              notReceiveOtp: "Didn't get OTP?",
              retryOtp: "Resend OTP again",
              canRetryIn: "you can retry in {{timeLeft}} seconds",
              login: "Login",
              changeEmail: "Change Email",
              home: "Return to home page",
              otpMustNumber: "OTP must be a number",
            },
            ResetPassword: {
              home: "Return to home page",
              title: "Enter email address",
              title2: "A link to reset your password will be emailed to you",
              emailField: "Email",
              resetPassword: "Reset password",
              modal: {
                title: "Email sent successfully",
                content: "Please check your email to reset your password",
                button: "Ok",
              },
            },
            SetupPassword: {
              title: "Create a password for",
              passwordField: "password",
              confirmPasswordField: "confirm password",
              setupPassword: "Create a password",
              suggestText: {
                1: "Must be 8-16 digits long.",
                2: "At least 1 English letter.",
                3: "At least 1 number.",
                4: "Thai language and special symbols are not supported.",
              },
              modal: {
                title: "Successfully set a new password",
                content: "Please log in to use",
                button: "Ok",
                error: {
                  title: "Error",
                  button: "Ok",
                },
              },
            },
          },
          roles: {
            index: {
              title: "Role",
              home: "Home",
              addButton: "Add role",
              list: "Roles",
              deleteRole: "Role data will be deleted and cannot be restored",
              deletedRole: "Delete",
              description: "Description",
              amount: "Amount of users",
              updatedAt: "Last update",
              deleteRoleSuccess: "Delete Role Success",
              alreadyInUse: "Already in use",
              cancel: "Cancel",
              delete: "Delete",
            },
            new: {
              addRole: "Add role",
              role: "Role",
              title: "General Data",
              home: "Home",
              detail: "Role detail",
              scope: "Scope Management",
              reportScope: "Scope of report",
              roleTitle: "Title",
              description: "Description",
              syncingNotification: "Sync Notification",
              read: "Read",
              edit: "Edit",
              admin: "Admin Management",
              permission: "Permission Management",
              report: "Report Management",
              setting: "Setting Management",
              employee: "Employee Management",
              budget: "Budget Management",
              request: "Request Management",
              exportAllReport: "Export all reports",
              medical: "Medical fee",
              medicalReimbursementPerEmployee:
                "Medical fee reimbursement report (per employee)",
              medicalReimbursementDepartment:
                "Report on medical fee reimbursement by department/affiliation",
              support: "Child support allowance",
              supportChildPerEmployee: "Child allowance report (per employee)",
              supportChildUnder18PerEmployee:
                "Report on financial support for children under 18 years of age (per employee)",
              supportChildHistoryDisbursement:
                "Report on history of child support disbursement",
              education: "Child education allowance",
              educationChildPerEmployee:
                "Report on child education assistance disbursement (per employee)",
              compensation: "Employee compensation",
              compensationFuneralDisbursementSupport:
                "Report on disbursement of funeral expenses (3 times)",
              compensationFuneralDisbursement:
                "Report on disbursement of funeral expenses",
              compensationFuneralDisbursementEquipment:
                "Report on disbursement of expenses for funeral equipment",
              summary:
                "Summary report for submitting financial and accounting work",
              summaryMedicalReimbursementDepartment:
                "Summary of medical treatment reimbursement according to department/affiliation",
              summaryChildEducationReimbursementDepartment:
                "Summary of child tuition reimbursement according to department/affiliation",
              summaryChildSupportReimbursementDepartment:
                "Summary of child support disbursement according to department/affiliation",
              summaryAllEachTimes: "Summary of all withdrawals, each time",
              affect: "Disbursement reconciliation report",
              affectChildSupportIncrease:
                "Child allowance report (increase) according to department/affiliation",
              affectChildSupportReduce:
                "Child allowance report (reduced) by department/affiliation",
              save: "Save",
              cancelEdit: "Cancel Edit",
              cancelConfirm:
                "Current data will not be saved. and cannot be restored",
              close: "Close Window",
              canceledEdit: "Canceled Edit",
            },
            edit: {
              home: "Home",
              indexTitle: "Role",
              editRole: "Edit role",
              cancelEdit: "Cancel Edit",
              cancelConfirm:
                "Current data will not be saved. and cannot be restored",
              close: "Close Window",
              canceledEdit: "Canceled Edit",
              detail: "Role detail",
              scope: "Scope Management",
              cancel: "cancel",
              save: "Save",
              roleTitle: "Title",
              description: "Description",
            },
          },
          insurances: {
            index: {
              home: "Home",
              title: "Insurance",
              addButton: "Add Insurance",
              list: "Insurances",
              year: "Years",
              amount: "Amount of Insurance",
              updatedAt: "Last update",
              status: "State",
              modalDescription:
                "Please specify the year. (Year must not be the same as already existing in the system)",
              duplicateTitle: "Duplicate Insurance",
              duplicateDescription:
                "The system will copy the {{year}} insurance package to create a prototype of the insurance package in the name {{secondYear}} for further modification and use.",
              cancel: "Cancel",
              duplicateSuccess: "Duplicate success",
              alreadyInUse: "Already in use",
              reset: "Reset",
              filter: "Filter",
              reEditing: "Modify the published insurance",
              reEditingInfo:
                "The modification will prevent HR from creating enrollments with this insurance until the new insurance is released again.",
              edit: "edit",
              deleteTitle: "Delete Insurance",
              deleteSuccess: "Delete success",
              deleteDescription: "Delete Insurance {{year}}",
              state: {
                all: "All",
                draft: "Draft",
                published: "Published",
                editing: "Editing",
                active: "Active",
                closed: "Close",
              },
            },
            package: {
              new: {
                save: "Save",
                cancel: "Cancel",
                name: "Package Name",
                ipd: "IPD",
                opd: "OPD",
                dental: "Dental",
                remark: "Remark",
              },
            },
          },
          settings: {
            index: {
              home: "Home",
              settingMenu: "Setting Menu",
              title: "Setting Info About Belive",
              titleDetail:
                "Details will be displayed in the Settings -> About Belive page of the application.",
              logo: "Logo",
              logoDetail:
                "Recommended to use a .jpg image with at least xxx x yyy px size.",
              syncSetting: "Sync settings for employee data.",
              syncSettingDetail:
                "Employee information will be used in HR's Enrollment creation process.",
              reportSetting: "Automatic report generate setting.",
              reportSettingDetail:
                "Use in the automatic report generate process of the system.",
              createReport: "Create Report Every",
              addImage: "Add Image",
              editImage: "Edit Image",
              name: "Company Name",
              nameTh: "Company Name (Th)",
              nameEn: "Company Name (En)",
              description: "Company Description",
              descriptionTh: "Company Description (Th)",
              descriptionEn: "Company Description (En)",
              email: "Email",
              phoneNumber: "Phone number",
              cancel: "Cancel",
              save: "Save",
              saveSuccess: "Save Success",
              syncEmData: "Sync Employees Data Every",
              cancelEdit: "Cancel Edit",
              cancelConfirm:
                "Current data will not be saved. and cannot be restored",
              close: "Close Window",
              canceledEdit: "Canceled Edit",
            },
          },
          reports: {
            index: {
              home: "Home",
              reportMenu: "Report Menu",
              report: "Report",
              reportSummary: "ReportSummary",
              filter: "filter",
              reset: "reset",
              show: "show",
              hide: "hide",
              filterTitle: "Filter",
              remove: "Remove",
              removeTitle: "Remove Report",
              removeButtonOK: "ลบรายงาน",
              removeButtonCancel: "ปิดหน้าต่างนี้",
              columns: {
                year: "Year",
                reportName: "Insurance company",
                reportType: "Create date",
                endDate: "Outgoing",
                startDate: "Incoming",
                createdAt: "State",
              },
              modal: {
                title: "Change report state",
                description: "Change report state to",
                statusSend: "sent",
                statusNotSend: "unsent",
                cancelButtonLabel: "Cancel",
                okButtonLabel: "Confirm ",
                generating: "Generatingreport",
              },
            },
          },
          employee: {
            index: {
              home: "Home",
              title: "Employee",
              syncButton: "Update Employee",
              filter: {
                title: "Filter",
                show: "Show",
                hide: "Hide",
                fullName: "Full name",
                email: "Email",
                status: "Status",
                code: "Code",
                department: "Department",
                employmentStartDate: "Employment start date from",
                employmentFinishDate: "Employment end date from",
                endDate: "Until",
                reset: "Reset",
                confirm: "Enter",
                yearlyRequest: "Year receiving benefits",
              },
              columns: {
                fullName: "Full name",
                email: "Email",
                code: "Code",
                department: "Department",
                employmentStartDate: "Employment start date",
                employmentFinishDate: "Employment end date",
                role: "Role",
                values: {
                  availableStatus: {
                    active: "Active",
                    inactive: "Inactive",
                  },
                },
              },
              syncEmployeeLoading: "Updating Employees",
              syncEmployeeError:
                "Something wrong on updating. Pleas contact admin",
            },
            edit: {
              title: "Employee detail",
              home: "Home",
              rootPath: "Employee",
              cancel: "Back",
              fullName: "Full name",
              email: "Email",
              status: "Status",
              code: "Code",
              phoneNumber: "Phone number",
              department: "Department",
              employmentStartDate: "Employment start date",
              employmentFinishDate: "Employment end date",
            },
          },
          budget: {
            index: {
              home: "Home",
              title: "Budget",
              filter: {
                title: "Filter",
                show: "Show",
                hide: "Hide",
                fullName: "Full name",
                code: "Code",
                reset: "Reset",
                confirm: "Enter",
                yearlyRequest: "Year receiving benefits",
              },
              columns: {
                fullName: "Full name",
                code: "Code",
                year: "Year",
                companyBudget: "Company Budget",
                budget: "total",
                companyDentalBudget: "Company Dental Budget",
                dentalBudget: "total",
              },
            },
            edit: {
              title: "Budget detail",
              home: "Home",
              rootPath: "Budget",
              cancel: "Back",
              code: "Code",
              fullName: "Full name",
              year: "Year receiving benefits",
              companyBudget: "Default company rights",
              budget: "Remaining company rights",
              companyDentalBudget: "Initial dental rights",
              dentalBudget: "Remaining dental rights",
            },
          },
          request: {
            index: {
              home: "Home",
              title: "Request",
              filter: "filter",
              reset: "reset",
              show: "show",
              hide: "hide",
              filterTitle: "Filter",
              remove: "Remove",
              refId: "เลขอ้างอิง",
              claimRequester: "ผู้ขอเบิก",
              department: "ฝ่าย/สังกัด",
              claimType: "ประเภทสวัสดิการ",
              claimName: "ชื่อสวัสดิการ",
              createdAt: "วันที่สร้าง",
              status: "สถานะ",
              disease: "โรค",
              refNo: "เลขที่ใบเสร็จ",
              transferDate: "วันที่ตามใบเสร็จ",
            },
            detail: {
              title: "รายละเอียดรายการขอเบิก",
              home: "หน้าแรก",
            },
          },
          paymentCycles: {
            index: {
              home: "หน้าแรก",
              title: "รอบการจ่ายเงิน",
              addPaymentCycle: "เพิ่มรอบการจ่ายเงิน",
              helperText:
                "*สามารถลบได้เฉพาะรอบที่มีสถานะ “ดำเนินการ” และไม่มีคำร้องอยู่ในรอบเท่านั้น",
            },
            detail: {
              index: {
                title: "จัดการคำร้อง",
                home: "หน้าแรก",
                paymentCycle: "รอบการจ่ายเงิน",
              },
              SummaryDetail: {
                requestAmount: "จำนวนคำร้อง",
                approvedAmount: "ยอดที่อนุมัติ",
              },
              PaymentCycleDetail: {
                name: "ชื่อรอบ",
                paymentDate: "วันที่จ่ายเงิน",
                info: "*ทุกคำร้องที่อยู่ในรอบจะถูกปรับวันที่จ่ายเงินเป็นวันนี้",
                okButtonLabel: "บันทึก",
              },
              PaymentCycleDetailTable: {
                title: "คำขอเบิกที่จ่ายเงินรอบนี้",
                waitingTitle: "คำร้องที่รอจัดการ",
                helperText: "*เลือกรายการที่ต้องการเพื่อแก้ไขรอบของคำร้องได้",
                refId: "เลขอ้างอิง",
                claimRequester: "ผู้ขอเบิก",
                department: "ฝ่าย/สังกัด",
                claimType: "ประเภทสวัสดิการ",
                claimName: "ชื่อสวัสดิการ",
                createdAt: "วันที่สร้าง",
                status: "สถานะ",
                disease: "โรค",
                refNo: "เลขที่ใบเสร็จ",
                transferDate: "วันที่ตามใบเสร็จ",
              },
              PaymentCycleSwitcher: {
                waitingOptions: "รอจัดการ",
                selected: "{{selected}} รายการที่ถูกเลือก:",
                moveAllTo: "ย้ายทั้งหมดไปยังรอบ",
                pleaseFillCycle: "โปรดระบุรอบ",
                moveAndSave: "ย้ายและบันทึก",
                errorModal: {
                  title: "ย้ายคำร้องไม่สำเร็จ",
                  info1: "ทุกคำร้องที่จะย้ายเข้ารอบการจ่ายเงินต้องมีสถานะเป็น",
                  info2: "เท่านั้น ",
                  info3: "กรุณาตรวจสอบและดำเนินการอีกครั้ง",
                  confirm: "ตกลง",
                },
                changePaymentModal: {
                  title: "ย้ายคำร้องไปรอบอื่น",
                  info1: "ต้องการย้ายคำร้อง",
                  info2: "ไปยังรอบการจ่ายเงิน",
                  info3: "หรือไม่",
                  confirm: "ย้ายและบันทึก",
                  cancel: "ยกเลิก",
                },
              },
            },
            PaymentCycleFilters: {
              confirm: "กรอง",
              reset: "รีเซ็ต",
              name: "ชื่อรอบ",
              status: "สถานะ",
              startPaymentDate: "วันที่จ่ายเงินตั้งแต่",
              endPaymentDate: "จนถึงวันที่",
              statusOptions: {
                IN_PROGRESS: "ดำเนินการ",
                CLOSED: "ปิดรอบ",
              },
            },
            PaymentCycleTable: {
              columns: {
                name: "ชื่อรอบ",
                requestAmount: "จำนวนคำร้อง",
                requestTotalPaymentAmount: "ยอดที่อนุมัติ",
                paymentDate: "วันที่จ่ายเงิน",
                status: "สถานะ",
              },
              confirmDeleteModal: {
                info1: "ต้องการให้ลบรอบการจ่ายเงิน",
                info2: "หรือไม่",
                warningInfo: "*ข้อมูลจะถูกลบและไม่สามารถกู้คืนได้",
                removePaymentCycle: "ลบรอบการจ่ายเงิน",
                cancelDelete: "ยกเลิก",
                confirmDelete: "ลบรอบการจ่ายเงิน",
              },
              changeStatusToCloseModal: {
                title: "ปรับสถานะรอบการจ่ายเงิน",
                info1: "ต้องการปรับสถานะรอบการจ่ายเงิน",
                info2: "เป็น",
                info3: "หรือไม่",
                subInfo:
                  "*รอบการเบิกนี้จะไม่สามารถแก้ไขได้ ยกเว้นจะปรับสถานะกลับเป็น “ดำเนินการ”",
                cancel: "ยกเลิก",
                confirm: "ปิดรอบ",
              },
              changeStatusToInProgressModal: {
                title: "ปรับสถานะรอบการจ่ายเงิน",
                info1: "ต้องการปรับสถานะรอบการจ่ายเงิน",
                info2: "เป็น",
                info3: "เพื่อแก้ไขข้อมูลหรือไม่",
                cancel: "ยกเลิก",
                confirm: "ปรับสถานะเป็นดำเนินการ",
              },
            },
            CreatePaymentCycleModal: {
              createPaymentCycle: "เพิ่มรอบการจ่ายเงิน",
              name: "ชื่อรอบ",
              paymentDate: "วันที่จ่ายเงิน",
              cancelButtonLabel: "ยกเลิก",
              okButtonLabel: "เพิ่มรอบการจ่ายเงิน",
            },
          },
        },
        auth: {
          ResetPassword: {
            forgotPassword: "Forgot Password",
            inputEmail: "Input email to create password for login",
            send: "send",
            link1: "URL link has already send to email \n{{email}}\n",
            link2: "Please click below link to create password for login",
          },
        },
      },
      components: {
        common: {
          languagesDropdown: {
            label: "language",
            lang: "English",
          },
          PopMenu: {
            logout: "Logout",
            logoutInfo1: "Do you want to logout? If you want, please press",
            logoutInfo2: "to logout.",
            confirmLogout: "Confirm",
            cancel: "Cancel",
            profile: "Profile",
            avatar: "AD",
          },
          MaterialIcon: {
            notFoundIcon: "Icon Not Found",
          },
          UploadAvatar: {
            avatar: "AD",
          },
        },
        advance: {
          AddressList: {
            total: "total {{amount}}",
            addressNo: "address {{index}}",
          },
          FileUploadList: {
            total: "total {{amount}}",
          },
        },
        SideBar: {
          appName: "eBenefits",
        },
        Table: {
          noData: "No Data",
          noDataOnFilter:
            "No search results were found. Please check the values in the filter again.",
        },
        FilterTableLayout: {
          title: "Filter",
          show: "show",
          hide: "hide",
        },
        FilterTableWithQueryParams: {
          confirm: "กรอง",
          reset: "รีเซ็ต",
        },
      },
      routes: {
        menu: {
          adminMenu: "Admin",
          roleMenu: "Role",
          insuranceMenu: "Insurance",
          settingMenu: "Setting",
          menu: "menu",
          insurance: "Insurance Management",
          enrollment: "Enrollment",
          report: "Export Report",
          employee: "Employee",
          budget: "Budget",
          request: "Request",
          fileBrowser: "File Browser",
          paymentCycle: "Payment Cycle",
        },
      },
      yup: {
        error: {
          required: "Field is required",
          invalidEmailSyntax: "Invalid email syntax",
          typeNumber: "Must be a number",
          GtZero: "Must be a number greater than zero",
          GteZero: "Must be a number greater than or equal zero",
          passwordNotMatch: "The password format is incorrect",
          wrongConfirmPassword: "Passwords don't match",
          reportDepartmentWarning: "Please select a department/affiliation.",
          relationWithOwnerWarning:
            "Please select a relationship with the petition owner.",
          startDateWarning:
            "Please select the date the transaction was completed from.",
          endDateWarning: "Please select until the end date.",
          startDateCompareWarning:
            "Please select a date for the comparison list from",
          endDateCompareWarning: "Please select until the end date.",
          reportNameWarning: "Please enter the name of the report",
        },
      },
      error: {
        required: "Field is required",
        saveError: "This insurance form has been published",
        accept: "Ok",
        saveErrorChildren:
          "This registration has already been published. Please press the (OK) button to refresh and try again.",
        reportTypeWarning: "Please select report type.",
      },
    },
  },
};

export default en;
