import {
  Box,
  DatePicker,
  Field,
  FilterTableWithQueryParams,
  Grid,
  Select,
  TextField,
} from "components";

import { ENUM_CLAIM_REQUEST_DETAIL_STATUS } from "constants/enums/claim_request_detail_status.enum";
import { ClaimRequestMappingStatus } from "constants/objects/claim_request_mapping_status";
import { compose, withHooks, withTranslation } from "enhancers";
import { get } from "lodash";
import paths from "routes/paths";
import { AppColor } from "theme/app-color";
import { gql, Yup } from "utils/helper";
import { waiting_requests_id } from "../PaymentCycleTable";

interface IClaimRequestForPaymentCycleFilterInput {
  startCreatedAt?: string;
  endCreatedAt?: string;
  startPaymentDate?: string;
  endPaymentDate?: string;
  status?: ENUM_CLAIM_REQUEST_DETAIL_STATUS;
  startReceiptDate?: string;
  endReceiptDate?: string;
  benefitTitle?: string;
  benefitType?: string;
  referenceId?: string;
  requester?: string;
  department?: string;
  receiptNo?: string;
}

export const claimRequestPaymentCycleFilterInitialValues: IClaimRequestForPaymentCycleFilterInput = {
  startCreatedAt: undefined,
  endCreatedAt: undefined,
  startPaymentDate: undefined,
  endPaymentDate: undefined,
  status: undefined,
  department: "",
  requester: "",
  benefitType: "",
  benefitTitle: "",
  referenceId: "",
  receiptNo: "",
  startReceiptDate: undefined,
  endReceiptDate: undefined,
};

export const createYupDateObject = (
  ownerKey: string,
  conditionKey: string,
  operation: "LTE" | "GTE",
  message = "วันที่เริ่มต้นต้องน้อยกว่าวันที่สิ้นสุด"
) => {
  const conditions = (value: any, conditionValue: any) => {
    if (operation === "LTE") return value <= conditionValue;
    return value >= conditionValue;
  };
  return Yup.date()
    .nullable()
    .notRequired()
    .test(`${ownerKey}-before-${conditionKey}`, message, function (value) {
      const conditionValue = get(this.parent, conditionKey);
      return !value || !conditionValue || conditions(value, conditionValue); // ตรวจสอบว่า start <= end
    });
};

const validationSchema = Yup.object().shape({
  startCreatedAt: createYupDateObject("startCreatedAt", "endCreatedAt", "LTE"),
  endCreatedAt: createYupDateObject("endCreatedAt", "startCreatedAt", "GTE"),
  startPaymentDate: createYupDateObject(
    "startPaymentDate",
    "endPaymentDate",
    "LTE"
  ),
  endPaymentDate: createYupDateObject(
    "endPaymentDate",
    "startPaymentDate",
    "GTE"
  ),
  startReceiptDate: createYupDateObject(
    "startReceiptDate",
    "endReceiptDate",
    "LTE"
  ),
  endReceiptDate: createYupDateObject(
    "endReceiptDate",
    "startReceiptDate",
    "GTE"
  ),
});

const PaymentCycleDetailFilters = ({
  t,
  isWaiting,
  departmentOptions,
  claimRequestTypesOptions,
  claimRequestTitleOptions,
}: any) => (
  <Box
    border={`1px solid ${AppColor["Text/Line"]}`}
    padding="22px 20px"
    borderRadius="8px"
    mt={6}
    mb={6}
  >
    <FilterTableWithQueryParams
      initialValues={claimRequestPaymentCycleFilterInitialValues}
      path={paths.paymentCycleDetailPath}
      validationSchema={validationSchema}
    >
      <Box display="flex" flexDirection="column">
        <Grid container spacing={6} mt={6}>
          <Grid item xs={3}>
            <Field
              fast
              component={DatePicker}
              name="startCreatedAt"
              label={t(".startCreatedAt")}
              fullWidth
              clearable
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              fast
              component={DatePicker}
              name="endCreatedAt"
              label={t(".endCreatedAt")}
              fullWidth
              clearable
            />
          </Grid>

          <Grid item xs={3}>
            <Field
              fast
              component={DatePicker}
              name="startPaymentDate"
              label={t(".startPaymentDate")}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              fast
              component={DatePicker}
              name="endPaymentDate"
              label={t(".endPaymentDate")}
              fullWidth
            />
          </Grid>

          {isWaiting && (
            <Grid item xs={3}>
              <Field
                fast
                component={Select}
                name="status"
                label={t(".status")}
                options={Object.values(ENUM_CLAIM_REQUEST_DETAIL_STATUS).map(
                  (value) => ({
                    label: ClaimRequestMappingStatus[value],
                    value: value,
                  })
                )}
                fullWidth
              />
            </Grid>
          )}

          <Grid item xs={isWaiting ? 3 : 6}>
            <Field
              fast
              component={Select}
              name="department"
              label={t(".department")}
              options={departmentOptions}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              fast
              component={TextField}
              name="requester"
              label={t(".requester")}
              fullWidth
              clearable
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              component={Select}
              name="benefitType"
              type="text"
              label="ประเภทสวัสดิการ"
              options={claimRequestTypesOptions}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              component={Select}
              name="benefitTitle"
              type="text"
              label="ชื่อสวัสดิการ"
              options={claimRequestTitleOptions}
              fullWidth
            />
          </Grid>

          <Grid item xs={3}>
            <Field
              component={TextField}
              name="referenceId"
              type="text"
              label="เลขอ้างอิง"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={TextField}
              name="receiptNo"
              type="text"
              label="เลขที่ใบเสร็จ"
              fullWidth
            />
          </Grid>

          <Grid item xs={3}>
            <Field
              fast={false}
              component={DatePicker}
              name="startReceiptDate"
              label="วันที่ตามใบเสร็จตั้งแต่"
              fullWidth
              // maxDate={values.endDate}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              fast={false}
              component={DatePicker}
              name="endReceiptDate"
              label="จนถึงวันที่"
              fullWidth
              // minDate={values.startDate}
            />
          </Grid>
        </Grid>
      </Box>
    </FilterTableWithQueryParams>
  </Box>
);

const API = {
  FETCH_DEPARTMENT_OPTIONS: gql`
    query FETCH_DEPARTMENT_OPTIONS {
      getDepartmentOption {
        label
        value
      }
    }
  `,
  FETCH_REQUEST_TYPE_OPTIONS: gql`
    query FETCH_REQUEST_TYPE_OPTIONS {
      claimRequestTypeOptions {
        label
        value
      }
    }
  `,
  FETCH_REQUEST_TITLE_OPTIONS: gql`
    query FETCH_REQUEST_TITLE_OPTIONS {
      claimRequestTitleOptions {
        label
        value
      }
    }
  `,
};

const enhancer = compose(
  withTranslation({
    prefix: "pages.main.paymentCycles.detail.PaymentCycleDetailFilters",
  }),
  withHooks((props: any, hooks: any) => {
    const { t } = props;
    const { useMemo, useParams, useUrlParam, useQuery } = hooks;
    const { id } = useParams();
    const queryParams = useUrlParam();
    const isWaiting = useMemo(() => id === waiting_requests_id, [id]);

    const { data: departmentOptionsData } = useQuery(
      API.FETCH_DEPARTMENT_OPTIONS,
      {
        fetchPolicy: "network-only",
      }
    );
    const departmentOptions = useMemo(() => {
      return departmentOptionsData?.getDepartmentOption;
    }, [departmentOptionsData]);

    const { data: claimRequestTypesData } = useQuery(
      API.FETCH_REQUEST_TYPE_OPTIONS,
      {
        fetchPolicy: "network-only",
      }
    );
    const claimRequestTypesOptions = useMemo(() => {
      return claimRequestTypesData?.claimRequestTypeOptions;
    }, [claimRequestTypesData]);

    const { data: claimRequestTitleData } = useQuery(
      API.FETCH_REQUEST_TITLE_OPTIONS,
      {
        fetchPolicy: "network-only",
      }
    );
    const claimRequestTitleOptions = useMemo(() => {
      return claimRequestTitleData?.claimRequestTitleOptions;
    }, [claimRequestTitleData]);

    return {
      t,
      isWaiting,
      departmentOptions,
      claimRequestTypesOptions,
      claimRequestTitleOptions,
    };
  })
);

export default enhancer(PaymentCycleDetailFilters);
