import { Box } from "components";
import { compose, withAuthorize, withHooks, withTranslation } from "enhancers";
import { toCurrency } from "utils/helper";
import { PAYMENT_CYCLE_DETAIL_API } from ".";
import { waiting_requests_id } from "../PaymentCycleTable";
import PaymentCycleDetail from "./PaymentCycleDetail";
import PaymentCycleSummaryDetail from "./PaymentCycleSummaryDetail";

const PaymentCycleDetailComponent = ({
  t,
  requestAmount,
  approvedAmount,
  initialValues,
  isShowPaymentCycleDetail,
  isPaymentCycleClosed,
}: any) => (
  <Box>
    <PaymentCycleSummaryDetail
      requestAmount={requestAmount}
      approvedAmount={approvedAmount}
    />
    {isShowPaymentCycleDetail && (
      <PaymentCycleDetail
        {...initialValues}
        isPaymentCycleClosed={isPaymentCycleClosed}
      />
    )}
  </Box>
);

const enhancer = compose(
  withAuthorize({
    mapPropsToValues: (props: any) => ({
      name: props.name,
      paymentDate: props.paymentDate,
    }),
  }),
  withTranslation({
    prefix: "pages.main.paymentCycles.detail.PaymentCycleDetail",
  }),
  withHooks((props: any, hooks: any) => {
    const { t, isPaymentCycleClosed } = props;
    const { useQuery, useParams, useMemo } = hooks;

    const { id } = useParams();
    const isShowPaymentCycleDetail = useMemo(() => id !== waiting_requests_id, [
      id,
    ]);
    const { data } = useQuery(
      PAYMENT_CYCLE_DETAIL_API.GET_PAYMENT_CYCLE_DETAIL,
      {
        variables: { id: isShowPaymentCycleDetail ? id : undefined },
      }
    );

    const requestAmount = useMemo(() => {
      const requestAmount = data?.paymentCycleDetail?.requestAmount || 0;
      return toCurrency(requestAmount, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }, [data?.paymentCycleDetail?.requestAmount]);

    const approvedAmount = useMemo(() => {
      const approvedAmount =
        data?.paymentCycleDetail?.requestTotalPaymentAmount || 0;
      return toCurrency(approvedAmount, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }, [data?.paymentCycleDetail?.requestTotalPaymentAmount]);

    return {
      t,
      requestAmount,
      approvedAmount,
      initialValues: {
        name: data?.paymentCycleDetail.name,
        paymentDate: data?.paymentCycleDetail.paymentDate,
      },
      isShowPaymentCycleDetail,
      isPaymentCycleClosed,
    };
  })
);

export default enhancer(PaymentCycleDetailComponent);
