import { GridSelectionModel } from "@material-ui/data-grid";
import { Box, Table, Typography } from "components";
import dayjs from "dayjs";
import { compose, withAuthorize, withHooks, withTranslation } from "enhancers";
import { useCallback } from "react";
import { AppColor } from "theme/app-color";
import { gql, paths } from "utils/helper";
import { waiting_requests_id } from "../PaymentCycleTable";
import PaymentCycleSwitcher from "./PaymentCycleSwitcher";

import { ENUM_CLAIM_REQUEST_DETAIL_STATUS } from "constants/enums/claim_request_detail_status.enum";
import { PERMISSIONS } from "constants/enums/permissions";
import { ClaimRequestMappingStatus } from "constants/objects/claim_request_mapping_status";
import { useLazyQuery } from "hooks";
import { isEmpty } from "lodash";
import { claimRequestPaymentCycleFilterInitialValues } from "./PaymentCycleDetailFilters";

const PaymentCycleDetailTableComponent = ({
  title,
  claimRequestIdList,
  t,
  columns = [],
  tableData = [],
  loading = false,
  handleSelectionChange,
  isCheckboxDisabled,
}: any) => (
  <Box
    border={`1px solid ${AppColor["Text/Line"]}`}
    padding="22px 20px"
    borderRadius="8px"
  >
    <Box display="flex" justifyContent="space-between">
      <Typography variant="Header/20">{title}</Typography>
      <Typography variant="Helper/12">{t(".helperText")}</Typography>
    </Box>
    <PaymentCycleSwitcher claimRequestIdList={claimRequestIdList} />
    {!loading && (
      <div style={{ marginTop: "32px" }}>
        <Table
          loading={loading}
          columns={columns}
          rows={tableData}
          density="compact"
          autoHeight
          disableSelectionOnClick
          checkboxSelection
          onSelectionModelChange={handleSelectionChange}
          componentsProps={{
            checkbox: {
              disabled: isCheckboxDisabled,
            },
          }}
        />
      </div>
    )}
  </Box>
);

export const PAYMENT_CYCLE_DETAIL_TABLE_API = {
  GET_CLAIM_REQUEST_PAYMENT_CYCLE: gql`
    query GET_CLAIM_REQUEST_PAYMENT_CYCLE(
      $paymentCycleId: String
      $filters: JSON
    ) {
      claimRequestsWithPaymentCycle(
        input: { paymentCycleId: $paymentCycleId, filters: $filters }
      ) {
        id
        referenceId
        info
        employee
        status
        createdAt
        displayStatus
      }
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withTranslation({
    prefix: "pages.main.paymentCycles.detail.PaymentCycleDetailTable",
  }),
  withHooks((props: any, hooks: any) => {
    const { t, isPaymentCycleClosed, hasPermission } = props;
    const { useParams, useMemo, useState, useEffect, useUrlParam } = hooks;
    const queryParams = useUrlParam();

    const [selected, setSelected] = useState([]);

    const { id } = useParams();

    const isShowPaymentCycleDetail = useMemo(() => id !== waiting_requests_id, [
      id,
    ]);
    const [fetchList, { data, loading, error }] = useLazyQuery(
      PAYMENT_CYCLE_DETAIL_TABLE_API.GET_CLAIM_REQUEST_PAYMENT_CYCLE,
      {
        onCompleted: () => {
          setSelected([]);
        },
        fetchPolicy: "network-only",
      }
    );

    const columns = useMemo(
      () => [
        {
          width: 300,
          field: "referenceId",
          headerName: t(".refId") || "",
        },
        {
          width: 200,
          field: "requesterName",
          headerName: t(".claimRequester") || "",
        },
        {
          width: 150,
          field: "department",
          headerName: t(".department") || "",
          type: "string",
        },
        {
          width: 150,
          field: "claimType",
          headerName: t(".claimType") || "",
          type: "string",
        },
        {
          width: 150,
          field: "claimName",
          headerName: t(".claimName") || "",
          type: "string",
        },
        {
          width: 150,
          field: "createdAt",
          headerName: t(".createdAt") || "",
          type: "string",
          renderCell: (createdAt: any) => {
            return dayjs(createdAt.value)
              .locale("th")
              .add(543, "year")
              .format("DD/MM/YYYY");
          },
        },
        {
          width: 150,
          field: "displayStatus",
          headerName: t(".status") || "",
          type: "string",
          renderCell: (status: any) => {
            return ClaimRequestMappingStatus[
              status.value as ENUM_CLAIM_REQUEST_DETAIL_STATUS
            ];
          },
        },
        {
          width: 150,
          field: "disease",
          headerName: t(".disease") || "",
          type: "string",
        },
        {
          width: 150,
          field: "receiptNo",
          headerName: t(".refNo") || "",
          type: "string",
        },
        {
          width: 150,
          field: "receiptDate",
          headerName: t(".transferDate") || "",
          type: "string",
          renderCell: (endDate: any) => {
            return endDate.value
              ? dayjs(endDate.value)
                  .locale("th")
                  .add(543, "year")
                  .format("DD/MM/YYYY")
              : "";
          },
        },
      ],
      [t]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data?.claimRequestsWithPaymentCycle.map((req: any) => {
        const { employee, info, status } = req;

        return {
          ...req,
          requesterName: `[${employee.employeeCode}] ${employee.firstName} ${employee.lastName}`,
          department: employee.department,
          claimType: info.type,
          claimName: info.title,
          status: status,
          receiptDate: info.values.inputs["receipt_date"]
            ? mapStringDate(info.values.inputs["receipt_date"])
            : "",
          disease: info.values.inputs["disease"] || "",
          receiptNo: info.values.inputs["receipt_no"] || "",
        };
      });
    }, [loading, data?.claimRequestsWithPaymentCycle, error]);

    const handleSelectionChange = useCallback((model: GridSelectionModel) => {
      setSelected(model);
    }, []);

    const title = useMemo(() => {
      if (isShowPaymentCycleDetail) return t(".title");
      return t(".waitingTitle");
    }, [t, isShowPaymentCycleDetail]);

    const isCheckboxDisabled = useMemo(() => {
      return (
        !hasPermission([PERMISSIONS.PAYMENT_CYCLE_EDIT]) || isPaymentCycleClosed
      );
    }, [hasPermission, isPaymentCycleClosed]);

    useEffect(() => {
      const params = isEmpty(queryParams) ? undefined : queryParams;

      const allowedKeys = Object.keys(
        claimRequestPaymentCycleFilterInitialValues
      );
      const hasInvalidKey =
        params && Object.keys(params).some((key) => !allowedKeys.includes(key));
      if (hasInvalidKey) paths.paymentCycleDetailPath(id).push();
      else {
        const variables = {
          paymentCycleId: isShowPaymentCycleDetail ? id : undefined,
          filters: params,
        };
        fetchList({ variables });
      }
    }, [queryParams, fetchList, id, isShowPaymentCycleDetail]);

    return {
      title,
      claimRequestIdList: selected,
      t,
      columns,
      tableData,
      handleSelectionChange,
      isCheckboxDisabled,
    };
  })
);

const mapStringDate = (dateString: any) => {
  const [day, month, year] = dateString.split("/").map(Number);
  const date = new Date(year, month - 1, day);
  return date;
};

export default enhancer(PaymentCycleDetailTableComponent);
