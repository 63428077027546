import { SvgIconProps } from "@material-ui/core";
import * as MuiIcon from "@material-ui/icons";
import { withTranslation } from "enhancers";
import React from "react";
import { AppColor } from "theme/app-color";
import T from "./T";
import Typography from "./Typography";

export type MuiIconCollections = keyof typeof MuiIcon;

interface CustomIconProps extends SvgIconProps {
  name?: MuiIconCollections;
  // t: Function;
}

export const MaterialIcon = (props: CustomIconProps) => {
  const { name, width, height, ...rest } = props;
  if (MuiIcon[name as MuiIconCollections])
    return React.createElement(MuiIcon[name as MuiIconCollections], {
      ...rest,
      style: { width, height },
    });
  else return <Typography variant="body1">{"notFoundIcon"}</Typography>;
};
