export enum ENUM_CLAIM_REQUEST_DETAIL_STATUS {
  "APPROVED" = "APPROVED",
  "WAITING" = "WAITING",
  "WAITING_0" = "WAITING_0",
  "WAITING_1" = "WAITING_1",
  "WAITING_2" = "WAITING_2",
  "WAITING_3" = "WAITING_3",
  "WAITING_4" = "WAITING_4",
  "WAITING_5" = "WAITING_5",
  "WAITING_6" = "WAITING_6",
  "WAITING_EMPLOYEE_UPDATE" = "WAITING_EMPLOYEE_UPDATE",
  "WAITING_HR_UPDATE" = "WAITING_HR_UPDATE",
}
