import { ReactComponent as FilterIcon } from "assets/icon/filter_outline.svg";
import { compose, withHooks, withTranslation } from "enhancers";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { Box, T, Typography } from "../common";

const FilterCountNotify = styled(Box)`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffa500;
  display: flex;
  padding: 2px;
  margin-left: 4px;
`;

const prefix = "components.advance.FilterTableLayout";
const prefixWithClient = "client." + prefix;

const FilterTableLayoutComponent = ({
  isShowFilter,
  filterCount,

  handleClickShowHide,

  children,
}: PropsWithChildren<any>) => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <FilterIcon />
          <span style={{ marginLeft: "4px" }}></span>
          <T variant="h6">{prefixWithClient + ".title"}</T>
          {filterCount > 0 && (
            <FilterCountNotify>
              <Typography variant="Body/14">{filterCount}</Typography>
            </FilterCountNotify>
          )}
        </Box>
        <Box display="flex" alignItems="center" onClick={handleClickShowHide}>
          <T
            variant="body1"
            style={{
              textDecoration: "underline",
              color: AppColor["Primary/Primary Text"],
              cursor: "pointer",
            }}
          >
            {isShowFilter
              ? prefixWithClient + ".hide"
              : prefixWithClient + ".show"}
          </T>
        </Box>
      </Box>
      {isShowFilter && <Box>{children}</Box>}
    </Box>
  );
};

const enhancer = compose(
  withTranslation({
    prefix,
  }),
  withHooks((props: PropsWithChildren<any>, hooks: any) => {
    const { children, filterCount } = props;
    const { useCallback, useState } = hooks;

    const [isShowFilter, setIsShowFilter] = useState(filterCount > 0);

    const handleClickShowHide = useCallback(() => {
      setIsShowFilter(!isShowFilter);
    }, [isShowFilter]);

    return { isShowFilter, handleClickShowHide, filterCount, children };
  })
);

export default enhancer(FilterTableLayoutComponent);
