import {
  Button,
  DatePicker,
  Field,
  Form,
  Grid,
  Notification,
  Paper,
  TextField,
  Typography,
} from "components";
import {
  compose,
  withAuthorize,
  withFormik,
  withHooks,
  withTranslation,
} from "enhancers";
import { PAYMENT_CYCLE_DETAIL_API } from ".";
import paths from "routes/paths";
import { PERMISSIONS } from "constants/enums/permissions";

const PaymentCycleDetailComponent = ({
  t,
  isSubmitButtonDisabled,
  isDisabledField,
}: any) => (
  <Paper mt={6} px={4} py={6}>
    <Typography variant="Header/20">{t(".title")}</Typography>
    <Form>
      <Grid container spacing={6} my={2}>
        <Grid item xs={12}>
          <Field
            fast
            component={TextField}
            name="name"
            label={t(".name")}
            fullWidth
            required
            disabled={isDisabledField}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            fast
            component={DatePicker}
            name="paymentDate"
            label={t(".paymentDate")}
            fullWidth
            required
            helperText={t(".info")}
            clearable
            disabled={isDisabledField}
          />
        </Grid>
      </Grid>
      {!isDisabledField && (
        <Button type="submit" disabled={isSubmitButtonDisabled}>
          {t(".okButtonLabel")}
        </Button>
      )}
    </Form>
  </Paper>
);

const enhancer = compose(
  withAuthorize(),
  withFormik({
    mapPropsToValues: (props: any) => ({
      name: props.name,
      paymentDate: props.paymentDate,
    }),
  }),
  withTranslation({
    prefix: "pages.main.paymentCycles.detail.PaymentCycleDetail",
  }),
  withHooks((props: any, hooks: any) => {
    const {
      t,
      requestAmount,
      approvedAmount,
      initialValues,
      isSubmitting,
      isValid,
      dirty,
      isPaymentCycleClosed,
      hasPermission,
    } = props;
    const { useHandleSubmit, useParams, useMemo, useMutation } = hooks;

    const { id } = useParams();

    const [updatePaymentCycleDetail] = useMutation(
      PAYMENT_CYCLE_DETAIL_API.UPDATE_PAYMENT_CYCLE_DETAIL,
      {
        onCompleted: () => {
          Notification.notify(t("client.success"));
          paths.paymentCyclePath().push();
        },
        refetchQueries: [
          {
            query: PAYMENT_CYCLE_DETAIL_API.GET_PAYMENT_CYCLE_DETAIL,
            variables: { id },
          },
        ],
        awaitRefetchQueries: true,
      }
    );

    useHandleSubmit(async (values: any) => {
      try {
        const newPaymentDate =
          initialValues.paymentDate === values.paymentDate
            ? undefined
            : values.paymentDate;
        await updatePaymentCycleDetail({
          variables: { id, name: values.name, paymentDate: newPaymentDate },
        });
      } catch (error) {
        console.log("Update payment cycle failed Error: ", error);
      }
    });

    const isSubmitButtonDisabled = useMemo(() => {
      return !isValid || isSubmitting || !dirty;
    }, [isSubmitting, isValid, dirty]);

    const isDisabledField = useMemo(
      () =>
        !hasPermission([PERMISSIONS.PAYMENT_CYCLE_EDIT]) ||
        isPaymentCycleClosed,
      [hasPermission, isPaymentCycleClosed]
    );

    return {
      t,
      requestAmount,
      approvedAmount,
      isSubmitButtonDisabled,
      isDisabledField,
    };
  })
);

export default enhancer(PaymentCycleDetailComponent);
