import { ReactComponent as PaymentIcon } from "assets/icon/payments.svg";
import { Box, Grid, MaterialIcon, Typography } from "components";
import { compose, withHooks, withTranslation } from "enhancers";
import { AppColor } from "theme/app-color";

const PaymentCycleSummaryDetailComponent = ({
  t,
  requestAmount,
  approvedAmount,
}: any) => (
  <Grid container spacing={6} mt={6}>
    <Grid item xs={6}>
      <Box
        bgcolor={AppColor["Other Light/Warning"]}
        height="80px"
        padding="16px"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Typography variant="Body/14">{t(".requestAmount")}</Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="Header/24" color={AppColor["Other/Warning"]}>
            {requestAmount}
          </Typography>
          <MaterialIcon
            name="AssignmentOutlined"
            htmlColor={AppColor["Other/Warning"]}
            fontSize="large"
          />
        </Box>
      </Box>
    </Grid>
    <Grid item xs={6}>
      <Box
        bgcolor={AppColor["Other Light/info"]}
        height="80px"
        padding="16px"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Typography variant="Body/14">{t(".approvedAmount")}</Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="Header/24" color={AppColor["Other/Info"]}>
            {approvedAmount}
          </Typography>
          <PaymentIcon fill={AppColor["Other/Info"]} width={40} height={40} />
        </Box>
      </Box>
    </Grid>
  </Grid>
);

const enhancer = compose(
  withTranslation({
    prefix: "pages.main.paymentCycles.detail.SummaryDetail",
  }),
  withHooks((props: any, hooks: any) => {
    const { t, requestAmount, approvedAmount } = props;

    return { t, requestAmount, approvedAmount };
  })
);

export default enhancer(PaymentCycleSummaryDetailComponent);
