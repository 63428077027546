import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { compose, withAuthorize, withHooks, withTranslation } from "enhancers";
import PageContent, { BreadcrumbsProps } from "layouts/PageContent";
import { useMemo } from "react";
import { homePath } from "utils/helper";
import CreatePaymentCycleModal from "./CreatePaymentCycleModal";
import PaymentCycleFilters from "./PaymentCycleFilters";
import PaymentCycleTable from "./PaymentCycleTable";
import { Box, Typography } from "components";

const PaymentCycleListPage = ({
  t,
  breadcrumbs,
  pageActions,
  isCreateModalOpen,
  handleCloseCreateModal,
}: any) => (
  <PageContent
    title={t(".title")}
    breadcrumbs={breadcrumbs}
    pageActions={pageActions}
    filter={<PaymentCycleFilters />}
  >
    <Box>
      <Box display="flex" justifyContent="space-between" mb={8}>
        <Typography variant="Header/20">{t(".title")}</Typography>
        <Typography variant="Helper/12">{t(".helperText")}</Typography>
      </Box>
      <PaymentCycleTable />
    </Box>

    <CreatePaymentCycleModal
      isOpen={isCreateModalOpen}
      onClose={handleCloseCreateModal}
    />
  </PageContent>
);

const enhancer = compose(
  withAuthorize(),
  withTranslation({
    prefix: "pages.main.paymentCycles.index",
  }),
  withHooks((props: any, hooks: any) => {
    const { t } = props;
    const { useCallback, useState } = hooks;

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".title"), path: null },
      ],
      [t]
    );

    const handleClickAddPaymentCycle = useCallback(() => {
      setIsCreateModalOpen(true);
    }, []);

    const handleCloseCreateModal = useCallback(() => {
      setIsCreateModalOpen(false);
    }, []);

    const pageActions = useMemo(
      () => [
        {
          children: t(".addPaymentCycle"),
          startIcon: <AddIcon />,
          onClick: handleClickAddPaymentCycle,
          color: "primary",
          permittedRoles: ["PERMISSION_MANAGEMENT_EDIT"],
        },
      ],
      [handleClickAddPaymentCycle, t]
    );

    return {
      breadcrumbs,
      pageActions,
      isCreateModalOpen,
      handleCloseCreateModal,
    };
  })
);

export default enhancer(PaymentCycleListPage);
